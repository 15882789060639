<template>
    <div class="LearnTeacher">
        <div class="flex-alc">
            <img src="@/assets/sf.png" class="sf-img" alt="" />
            <div class="tea-list">师傅列表</div>
        </div>
        <el-divider></el-divider>
        <div class="seacher">
            <img src="@/assets/ic_ss3@2x.png" class="seacher-img" alt="" />
            <input
                type="text"
                class="inp"
                placeholder="搜索师傅"
                @keyup.enter="getTeacher(1, 1)"
                v-model="search"
            />
            <div class="seacher-button click" @click="getTeacher(1)"></div>
        </div>
        <div class="flex wrap mt-29">
            <MasterAndApprentice
                v-for="(item, index) in teacherList"
                :teaObj="item"
                :key="'tea' + index"
                @wantTeacher="wantTeacher"
                @cancelApply="cancelApply"
                class="mb-15"
                :class="{ 'mr-18': (index + 1) % 4 != 0 }"
            ></MasterAndApprentice>
        </div>
        <div class="flex-jsc mt-32 mb-6">
            <Pagination
                :pageSize="8"
                :total="total"
                :page="page"
                @getPage="getPage"
            ></Pagination>
        </div>

        <el-dialog
            :visible.sync="showTeacher"
            class="dialog-apply"
            width="438px"
            :show-close="true"
        >
            <div class="title">拜师申请</div>
            <el-divider></el-divider>
            <div class="text">您将提交拜师申请给</div>
            <div class="text block">{{ teacherObj.name }}</div>
            <el-input
                type="textarea"
                class="inp"
                :rows="4"
                placeholder="说点什么吧..."
                v-model="textarea"
            >
            </el-input>
            <div class="submit click" @click="submit">提交</div>
        </el-dialog>
        <EvaluateSuccess v-if="isSuccess" :noBack="false"></EvaluateSuccess>
    </div>
</template>

<script>
    import MasterAndApprentice from "@/components/MasterAndApprentice.vue";
    import Pagination from "@/components/Pagination.vue";
    import EvaluateSuccess from "@/components/EvaluateSuccess.vue";
    export default {
        name: "LearnTeacher",
        components: {
            MasterAndApprentice,
            Pagination,
            EvaluateSuccess,
        },
        data() {
            return {
                search: "",
                teacherList: [], //拜师列表
                total: 0,
                teacherObj: {}, //拜师对象
                showTeacher: false, //师傅申请弹框
                textarea: "", //申请文本备注
                isSuccess: false, //是否提交成功
                page: 1,
            };
        },

        mounted() {
            this.getTeacher();
        },

        methods: {
            //提交申请
            submit() {
                this.$https
                    .post("/api/master/apply", {
                        id: this.teacherObj.id,
                        reason: this.textarea,
                    })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.getTeacher();
                        this.isSuccess = true;
                        this.showTeacher = false;
                    });
            },
            //取消拜师申请
            cancelApply(e) {
                this.$https
                    .post("/api/master/withdraw", {
                        id: e.id,
                    })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.getTeacher();
                    });
            },
            //我要拜师
            wantTeacher(e) {
                this.teacherObj = e;
                this.showTeacher = true;
            },
            //分页
            getPage(e) {
                this.getTeacher(e);
                this.page = e;
            },
            //拜师列表
            getTeacher(page = 1, type = 0) {
                if (type) {
                    this.page = 1;
                }
                this.$https
                    .get("/api/master/list", {
                        page,
                        limit: 8,
                        search: this.search,
                    })
                    .then((res) => {
                        this.teacherList = res.data.list;
                        this.total = res.data.total_count;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.LearnTeacher {
    width: 100%;
    background: white;
    border-radius: 14px;
    padding: 20px;
    .dialog-apply {
        /deep/.el-dialog {
            border-radius: 10px;
            .el-dialog__header {
                padding: 0;
            }
            .el-dialog__body {
                padding: 15px 0 0 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    text-align: center;
                }
                .el-divider--horizontal {
                    margin-bottom: 31px;
                    height: 2px;
                }
                .text {
                    font-size: 14px;
                    font-weight: 500;
                    color: #b9b9b9;
                    margin-bottom: 12px;
                    text-align: center;
                }
                .block {
                    color: #000;
                }
                .inp {
                    width: 289px;
                    margin: auto;
                    margin-top: 13px;
                    margin-bottom: 38px;
                }
                .submit {
                    width: 166px;
                    height: 33px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 17px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 58px;
                }
            }
        }
    }
    .sf-img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }
    .tea-list {
        font-size: 18px;
        font-weight: 500;
        color: #2d2d2d;
    }
    .el-divider--horizontal {
        margin-top: 21px;
        margin-bottom: 28px;
        height: 2px;
    }
    .seacher {
        width: 342px;
        height: 48px;
        position: relative;
        margin: auto;
        .seacher-img {
            width: 100%;
            height: 100%;
        }
        .inp {
            position: absolute;
            left: 15px;
            top: 0;
            width: 280px;
            height: 48px;
            outline: none;
            border: none;
            background: #fff3f3;
        }
        .seacher-button {
            width: 48px;
            height: 48px;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
</style>